export const organizations = [
  {
    title: 'Synoptic Intelligence Network',
    position: 'Founding Engineer',
    timeActivity: '2023 — Present',
    location: 'Warsaw, Poland',
  },
  {
    title: 'Instinctools',
    position: 'Senior Software Engineer',
    timeActivity: '2017 — 2023',
    location: 'Minsk, Belarus —> Warsaw, Poland',
  },
];
